"use strict";

(function () {
  function zeqh() {
    // Get all "zeqh" elements
    var $zeqh_els = Array.prototype.slice.call(document.querySelectorAll(".zeqh"), 0); // Check if there are any

    if ($zeqh_els.length > 0) {
      var elements = []; // Identify sizes

      $zeqh_els.forEach(function (el) {
        var ep = el;
        var top = 0;

        while (ep != null) {
          top += ep.offsetTop;
          ep = ep.offsetParent;
        }

        if (!elements[top]) {
          elements[top] = [];
        }

        elements[top].push(el);
      });
      var aborttherest = false;
      elements.forEach(function (els, idx) {
        if (aborttherest) {
          return;
        } // Ensure all elements are the same height


        var maxheight = 0;
        var heightsame = true;
        els.forEach(function (el) {
          if (el.clientHeight != maxheight) {
            if (maxheight != 0) {
              heightsame = false;
            }

            if (el.clientHeight > maxheight) {
              maxheight = el.clientHeight;
            }
          }
        });

        if (!heightsame) {
          // Make them the same height
          els.forEach(function (el) {
            el.style.height = maxheight + "px";
          });
          aborttherest = true;
        }
      });

      if (aborttherest) {
        zeqh();
      }
    }
  }

  document.addEventListener("DOMContentLoaded", zeqh);
  window.addEventListener("resize", zeqh);
})();
